<template>
  <div class="main">
    <van-nav-bar
      fixed
      title="卡券号核销"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="searchGigBox">
      <div class="searchBox">
        <img src="@/assets/images/search.png" alt="" />
        <input
          type="text"
          placeholder-class="palce"
          placeholder="请输入卡券号"
          clearable
          v-model="searchForm.ticketNum"
        />
        <img
          v-if="searchForm.ticketNum"
          src="@/assets/images/clear.png"
          alt=""
          @click="searchForm.ticketNum = ''"
        />
      </div>
      <span @click="handleSearch">搜索</span>
    </div>
    <div class="stepBox stepBox-1">
      <p>第一步、搜索卡券</p>
      <p>请顾客出示卡券</p>
    </div>

    <div class="stepBox">
      <p>第二步、验证卡券信息</p>
      <p>验证卡券是否有效</p>
    </div>

    <div class="stepBox">
      <p>第三步、使用完成</p>
      <p>确认核销，查看使用结果</p>
    </div>
  </div>
</template>
<script>
import { Field, Notify, NavBar } from "vant";
export default {
  components: {
    vanField: Field,
    vanNavBar: NavBar,
  },
  data() {
    return {
      submitLoading: false,
      searchForm: {
        ticketNum: "",
      },
    };
  },

  created() {},

  methods: {
    // 点击返回
    onClickLeft() {
      this.$router.go(-1);
    },

    handleSearch() {
      if (!this.searchForm.ticketNum) {
        Notify({
          type: "warning",
          message: "请先输入卡券号！",
        });
        return;
      }
      this.searchForm.ticketNum = this.searchForm.ticketNum
        .trim()
        .substring(0, 100);
      this.$router.push({
        path: "/confirmVerify",
        query: {
          couponNum: this.searchForm.ticketNum.trim(),
          type: 2, //1扫码，2券码
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css.scss";
.main ::v-deep .van-nav-bar .van-icon {
  color: $--font-color;
}
.main {
  width: 375px;
  height: 100%;
  overflow: scroll;
  background-color: $--bg-color;
  border: 0.01px solid rgba(0, 0, 0, 0);
  .stepBox {
    width: 345px;
    margin: 20px auto 0;
    p:first-child {
      font-size: 14px;
      color: $--font-color;
    }
    p:last-child {
      font-size: 12px;
      color: $--gray-color;
      margin-top: 5px;
    }
  }
  .stepBox-1 {
    margin: 125px auto 0;
  }
  .searchGigBox {
    width: 100%;
    height: 50px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    top: 0rem;
    left: 0;
    z-index: 10;
    margin-top: 45px;
    span {
      width: 36px;
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      color: $--font-color;
      margin: 7px 15px 0 0;
    }
    .searchBox {
      width: 300px;
      height: 35px;
      float: left;
      margin: 7px 0rem 0 15px;
      background-color: #f1f2f5;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 10px;
      box-sizing: border-box;
      border-radius: 5px;
      img {
        width: 20px;
        height: 20px;
        margin: 7px 0 0 0;
      }
      input {
        width: 300px;
        height: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        background-color: #f1f2f5;
        border: none;
        font-size: 14px;
      }
      input::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #c0c5cc;
        font-size: 14px;
      }

      input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #c0c5cc;
        font-size: 14px;
      }

      input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #c0c5cc;
        font-size: 14px;
      }

      input::-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #c0c5cc;
        font-size: 14px;
      }
    }
  }
}
</style>
